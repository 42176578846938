export const static_translation = {
    "header_link1": "Accueil",
    "header_link2": "Solution",
    "header_link3": "Services",
    "header_link4": "Packs",
    "header_link5": "Contact",
    "homebanner_title": "Référencez votre site et faites de la publicité facilement sur internet",
    "homebanner_action": "Lire la suite",
    "homehowworks_title": "Une solution clé en main",
    "homehowworks_text": "Grâce à nos outils de publicité en ligne, vous pouvez en quelques clics partager votre site web partout dans le monde ou cibler uniquement certains pays.",
    "homehowworks_title1": "Simple",
    "homehowworks_text1": "Nous nous occupons de tout pour vous, confiez-nous vos objectifs et nous faisons le reste.",
    "homehowworks_title2": "Rapide",
    "homehowworks_text2": "En quelques clics, vous serez visible par des milliers de visiteurs chaque jour.",
    "homehowworks_title3": "Sécurisé",
    "homehowworks_text3": "Payez en ligne en toute sécurité grâce à notre module de paiement disponible pour toutes les cartes de crédit.",
    "homemoreinfos_title1": "Petite annonce en ligne",
    "homemoreinfos_text1": "Nous pouvons créer pour vous des petites annonces en ligne sur différentes plateformes.",
    "homemoreinfos_title2": "Forums",
    "homemoreinfos_text2": "L'accès aux forums était souvent réservé aux membres, nous pouvons insérer vos liens pour vous.",
    "homemoreinfos_title3": "SEO et référencement Google",
    "homemoreinfos_text3": "Nous assurons un référencement sur la première page de google en fonction de vos mots clés.",
    "homemoreinfos_title": "Des services adaptés à vos besoins",
    "homemoreinfos_text": "Nous proposons différents services pour vous faire de la publicité sur internet. Grâce à une technologie innovante, nous pouvons publier votre site et le référencer sur des centaines de plateformes.",
    "homeknowmore_title1": "Plan de base",
    "homeknowmore_text1_1": "Petites annonces pendant 1 semaine",
    "homeknowmore_text1_2": "Lien dans les forums (1x par semaine)",
    "homeknowmore_text1_3": "SEO de base",
    "homeknowmore_text1_4": "Support par email",
    "homeknowmore_title2": "Plan Bronze",
    "homeknowmore_text2_1": "Petites annonces pendant 1 semaine",
    "homeknowmore_text2_2": "Lien dans les forums (1x par semaine)",
    "homeknowmore_text2_3": "SEO Premium",
    "homeknowmore_text2_4": "Support par téléphone 5/7j",
    "homeknowmore_title3": "Plan Premium",
    "homeknowmore_text3_1": "Petites annonces pendant 1 semaine",
    "homeknowmore_text3_2": "Lien dans les forums (2x par semaine)",
    "homeknowmore_text3_3": "Référencement Premium",
    "homeknowmore_text3_4": "Support 7/7 par téléphone",
    "homeknowmore_action": "Acheter maintenant",
    "homeknowmore_options_title": "Payer une facture",
    "homeknowmore_options_text": "Vous avez un contrat spécifique avec nous et vous souhaitez régler votre facture directement sur internet ? C'est simple, facile et sécurisé, cliquez ci-dessous et laissez-vous guider.",
    "homeknowmore_options_action": "Payer ma facture",
    "homeknowmore_title": "Différents packs selon vos objectifs",
    "homeknowmore_text": "Plusieurs formules sont disponibles selon vos besoins et objectifs. Que ce soit pour des petites annonces, des forums, du SEO ou encore du SEA, nous vous accompagnons dans votre démarche.",
    "footer_title": "Nous contacter",
    "footer_text": "Contactez-nous pour plus d'informations sur nos offres et pour des devis personnalisés.<br />Nous sommes à votre disposition du lundi au vendredi de 9h à 19h.",
    "footer_link1": "CGU",
    "footer_link2": "CGV",
    "purchase_successfuly_title": "Achat réussi",
    "purchase_successfuly_text": "Votre paiement a été confirmé avec succès, vous pouvez désormais booster vos annonces !",
    "purchase_successfuly_action": "Terminer",
    "purchase_cancel_title": "Achat échoué",
    "purchase_cancel_text": "Votre paiement ne peut pas être confirmé avec succès, réessayez plus tard",
    "purchase_cancel_action": "Ok",
    "buy_form_firstname": "Prénom*",
    "buy_form_lastname": "Nom*",
    "buy_form_email": "Adresse email*",
    "buy_form_phone": "Téléphone",
    "buy_form_company": "Société",
    "buy_form_url": "URL de votre site à référencer",
    "buy_form_amount": "Montant*",
    "buy_form_validation": "Accepter les conditions.",
    "buy_form_title": "Effectuer un paiement via Stripe",
    "buy_form_text1": "Pour nous envoyer un paiement par Stripe, veuillez remplir le formulaire ci-dessous.",
    "buy_form_text2": "Vous serez ensuite redirigé vers Stripe, vérifiez les informations de paiement puis choisissez votre moyen de paiement comme vous avez l'habitude de le faire.",
    "buy_terms_label": "En cochant cette case, vous acceptez",
    "buy_terms_text": "nos CGV",
    "buy_action": "Payer avec Stripe",
    "terms_title": "Conditions générales d'utilisation",
    "terms_text1": "L'utilisation du site implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites.",
    "terms_text2": "Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, sans préavis, les utilisateurs du site sont donc invités à les consulter de manière régulière. Wall Street Web se réserve également le droit de céder, transférer, sans préavis les droits et/ou obligations des présentes CGU et mentions légales.",
    "terms_text3": "En continuant à utiliser le site, l'utilisateur reconnaît accepter les modifications des conditions générales intervenues.",
    "terms_text4": "Description des services fournis",
    "terms_text5": "Le site www.pubclickmarketing.com a pour objet de fournir une information concernant l’ensemble des activités de la société. Le propriétaire du site s'efforce de fournir des informations aussi précises que possible. Toutefois, il ne saurait être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait de tiers partenaires qui fournissent ces informations.",
    "terms_text6": "Toutes les informations proposées sont données à titre indicatif, ne sont pas exhaustives, et sont susceptibles d'évoluer. Elles sont données sous réserve de modifications ayant été apportées depuis leur mise en ligne.",
    "terms_text7": "Des espaces interactifs sont à la disposition des utilisateurs. Le propriétaire du site se réserve le droit de supprimer, sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en Suisse, notamment aux dispositions relatives à la protection des données. Le cas échéant, le propriétaire du site se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l'utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte, photographie...).",
    "terms_text8": "Limites de responsabilité",
    "terms_text9": "Le site www.pubclickmarketing.com ne saurait être tenu responsable des erreurs typographiques ou inexactitudes figurant sur le service, ni de tout dommage subi du fait de son utilisation. L'utilisateur reste responsable de son équipement et de son utilisation, de la même manière qu'il supporte seul les coûts directs ou indirects consécutifs à sa connexion à Internet.",
    "terms_text10": "L'utilisateur dégage la responsabilité de www.pubclickmarketing.com pour tout dommage qu'il pourrait subir ou faire subir, directement ou indirectement, du fait des services proposés. Seule la responsabilité de l'utilisateur est engagée par l'utilisation du service proposé et il dégage expressément le site www.pubclickmarketing.com de toute responsabilité vis-à-vis de tiers.",
    "terms_text11": "Le site www.pubclickmarketing.com contient un certain nombre de liens hypertextes vers d'autres sites (partenaires, informations...) mis en place avec l'autorisation du propriétaire du site. Cependant, le propriétaire du site n'a pas la possibilité de vérifier le contenu des sites ainsi visités et décline donc toute responsabilité de ce fait quand aux risques éventuels de contenus illicites.",
    "terms_text12": "Propriété intellectuelle",
    "terms_text13": "Le propriétaire du site est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels…",
    "terms_text14": "Toute reproduction, représentation, modification, publication, adaptation totale ou partielle des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable à l'email : support@wallstreetweb.net.",
    "terms_text15": "Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.",
    "terms_text16": "Protection des biens et des personnes - gestion des données personnelles :",
    "terms_text17": "www.pubclickmarketing.com ne collecte des informations personnelles relatives à l'utilisateur que pour le besoin de certains services proposés par le site www.pubclickmarketing.com. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est alors précisé à l'utilisateur du site www.pubclickmarketing.com l’obligation ou non de fournir ces informations.",
    "terms_text18": "Tout utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition aux données personnelles le concernant. Pour exercer ce droit, adressez votre demande à www.pubclickmarketing.com par email : email du webmaster ou en effectuant sa demande écrite et signée, accompagnée d'une copie du titre d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée.",
    "terms_text19": "Aucune information personnelle de l'utilisateur du site www.pubclickmarketing.com n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers Seule l'hypothèse du rachat du site www.pubclickmarketing.com et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site www.pubclickmarketing.com.",
    "terms_text20": "Le site www.pubclickmarketing.com est déclaré à la CNIL sous le numéro 0.",
    "terms_text21": "Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.",
    "terms_text22": "Liens hypertextes et cookies :",
    "terms_text23": "L'utilisateur est informé que lors de ses visites sur le site www.pubclickmarketing.com, un ou plusieurs cookies peuvent s'installer automatiquement sur son ordinateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.",
    "terms_text24": "Le paramétrage du logiciel de navigation permet d'informer de la présence de cookies et éventuellement de refuser de la manière décrite à l'adresse suivante : www.cnil.fr",
    "terms_text25": "Le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à certains services. L'utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l'installation des cookies :",
    "terms_text26": "Sous Internet Explorer : onglet outil / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok. Sous Netscape : onglet Édition / Préférences. Cliquez sur Avancé et choisissez Désactiver les cookies. Valider sur Ok.",
    "terms_text27": "Loi applicable et juridiction compétente :",
    "terms_text28": "Tout litige en relation avec l'utilisation du site www.pubclickmarketing.com est soumis au droit suisse. L'utilisateur et www.pubclickmarketing.com conviennent de se soumettre à la compétence exclusive des tribunaux suisses en cas de litige.",
    "terms_text29": "Mentions légales",
    "terms_text30": "Responsable éditorial",
    "policy_title": "Préambule",
    "policy_text1": "En validant son paiement, le Client déclare accepter sans réserve l'intégralité des présentes conditions générales de vente.",
    "policy_text2": "Article 1 - Objet",
    "policy_text3": "Le présent contrat a pour objet le paiement en ligne de factures à Wall Street Web depuis le site publickmarketing.",
    "policy_text4": "Article 2 - Formules - Durée",
    "policy_text5": "Le Client paie l'un des services définis aux termes de son contrat établi au préalable et bénéficiera pendant la période contractuelle d'un accès au service et selon le tarif en vigueur au jour de la commande.",
    "policy_text6": "Article 3 - Tarifs et modalités de paiement",
    "policy_text7": "Les tarifs correspondant à la réalisation d'un service ou à l'accès à un service en ligne.",
    "policy_text8": "Pour obtenir un compte et commencer à payer vos factures, vous devez déjà être client d'un service proposé par Wall Street Web, dans ce cas contactez-nous pour obtenir vos identifiants.",
    "policy_text9": "Plusieurs modes de paiement sont proposés :",
    "policy_text10": "Carte bancaire selon une procédure sécurisée (SSL) PostFinance Bulletin de versement Article 4 - Utilisation du code confidentiel L'identifiant et le mot de passe permettant au Client de s'identifier et de se connecter à une partie du Site lui permettant de régler ses factures. Ils ne peuvent être modifiés qu'à la demande du Client ou à l'initiative de Wall Street Web. Le Client est pleinement responsable de l'utilisation des informations personnelles d'identification le concernant. Le Client s'engage à les garder secrètes et à ne pas les divulguer sous quelque forme que ce soit. Toute transaction effectuée à l'aide de l'identifiant et du mot de passe du Client est réputée avoir été effectuée par le Client.",
    "policy_text11": "Toute autre utilisation est soumise à l'autorisation préalable et expresse de Wall Street Web.",
    "policy_text12": "En cas de perte ou de vol de l'un des éléments personnels d'identification le concernant, le Client devra choisir un nouveau mot de passe et un nouvel identifiant et prévenir Wall Street Web par courrier ou e-mail à l'adresse suivante : support@wallstreetweb.net.",
    "policy_text13": "Article 5 - Validité des échanges électroniques Le Client reconnaît la validité et la valeur probante des échanges et enregistrements électroniques effectués par publickmarketing et accepte que lesdits enregistrements reçoivent la même valeur probante qu'un écrit signé manuscrit.",
    "policy_text14": "Article 6 - Résiliation En cas de manquement du Client à l'une de ses obligations essentielles, publickmarketing. se réserve le droit de suspendre immédiatement et de plein droit la fourniture du Service et/ou de résilier le présent contrat après une mise en demeure restée sans effet pendant un mois ou, sans préavis.",
    "policy_text15": "En cas de résiliation anticipée, publickmarketing ne procédera en aucun cas au remboursement des sommes versées en tout ou partie par le client.",
    "policy_text16": "Article 7 - Droit applicable et tribunaux compétents Le présent contrat est soumis au droit suisse. En cas de litige relatif à l'application ou à l'interprétation du présent contrat, les parties conviennent de rechercher une solution amiable. A défaut, les tribunaux suisses seront seuls compétents.",
}