export const static_translation = {
    "header_link1": "Home",
    "header_link2": "Soluzione",
    "header_link3": "Servizi",
    "header_link4": "Pacchetti",
    "header_link5": "Contatti",
    "homebanner_title": "Fai riferimento al tuo sito e pubblicizza facilmente su Internet",
    "homebanner_action": "Scopri di più",
    "homehowworks_title": "Una soluzione chiavi in ​​mano",
    "homehowworks_text": "Grazie ai nostri strumenti pubblicitari online, puoi condividere il tuo sito Web in qualsiasi parte del mondo o indirizzarti solo a determinati paesi in pochi clic.",
    "homehowworks_title1": "Semplice",
    "homehowworks_text1": "Ci prendiamo cura di tutto per te, indicaci i tuoi obiettivi e noi facciamo il resto.",
    "homehowworks_title2": "Veloce",
    "homehowworks_text2": "In pochi clic, sarai visibile a migliaia di visitatori ogni giorno.",
    "homehowworks_title3": "Sicuro",
    "homehowworks_text3": "Paga online in modo sicuro grazie al nostro modulo di pagamento disponibile per tutte le carte di credito.",
    "homemoreinfos_title1": "Annuncio online",
    "homemoreinfos_text1": "Possiamo creare annunci online per te su diverse piattaforme.",
    "homemoreinfos_title2": "Forum",
    "homemoreinfos_text2": "L'accesso ai forum era spesso riservato ai membri, possiamo inserire i tuoi link per te.",
    "homemoreinfos_title3": "SEO e Google SEO",
    "homemoreinfos_text3": "Garantiamo un SEO sulla prima pagina di Google in base alle tue parole chiave.",
    "homemoreinfos_title": "Servizi su misura per le tue esigenze",
    "homemoreinfos_text": "Offriamo vari servizi per pubblicizzarti su Internet. Grazie alla tecnologia innovativa, possiamo pubblicare il tuo sito e farvi riferimento su centinaia di piattaforme.",
    "homeknowmore_title1": "Piano base",
    "homeknowmore_text1_1": "Annunci classificati per 1 settimana",
    "homeknowmore_text1_2": "Link nei forum (1 volta a settimana)",
    "homeknowmore_text1_3": "SEO di base",
    "homeknowmore_text1_4": "Supporto via e-mail",
    "homeknowmore_title2": "Piano Bronzo",
    "homeknowmore_text2_1": "Annunci classificati per 1 settimana",
    "homeknowmore_text2_2": "Link nei forum (1 volta a settimana)",
    "homeknowmore_text2_3": "SEO Premium",
    "homeknowmore_text2_4": "Supporto telefonico 5/7 giorni",
    "homeknowmore_title3": "Piano Premium",
    "homeknowmore_text3_1": "Annunci classificati per 1 settimana",
    "homeknowmore_text3_2": "Link nei forum (2 volte a settimana)",
    "homeknowmore_text3_3": "SEO Premium",
    "homeknowmore_text3_4": "Supporto telefonico 7/7",
    "homeknowmore_action": "Acquista ora",
    "homeknowmore_options_title": "Paga una fattura",
    "homeknowmore_options_text": "Hai un contratto specifico con noi e vuoi pagare la tua fattura direttamente su Internet? È semplice, facile e sicuro, clicca qui sotto e lasciati guidare.",
    "homeknowmore_options_action": "Paga la mia fattura",
    "homeknowmore_title": "Diversi pacchetti a seconda dei tuoi obiettivi",
    "homeknowmore_text": "Sono disponibili diverse formule a seconda delle tue esigenze e dei tuoi obiettivi. Che si tratti di annunci classificati, forum, SEO o anche SEA, ti accompagniamo nel tuo approccio.",
    "footer_title": "Contattaci",
    "footer_text": "Contattaci per maggiori informazioni sulle nostre offerte e per preventivi personalizzati.<br />Siamo a tua disposizione dal lunedì al venerdì dalle 9:00 alle 19:00.",
    "footer_link1": "CGU",
    "footer_link2": "T&C",
    "purchase_successfuly_title": "Acquisto riuscito",
    "purchase_successfuly_text": "Il tuo pagamento è stato confermato con successo, ora puoi potenziare i tuoi annunci!",
    "purchase_successfuly_action": "Termina",
    "purchase_cancel_title": "Acquisto fallito",
    "purchase_cancel_text": "Il tuo pagamento non può essere confermato con successo, riprova più tardi",
    "purchase_cancel_action": "Ok",
    "buy_form_firstname": "Nome*",
    "buy_form_lastname": "Cognome*",
    "buy_form_email": "Indirizzo email*",
    "buy_form_phone": "Telefono",
    "buy_form_company": "Azienda",
    "buy_form_url": "URL del tuo sito a cui fare riferimento",
    "buy_form_amount": "Importo*",
    "buy_form_validation": "Accetta i termini.",
    "buy_form_title": "Effettua un pagamento tramite Stripe",
    "buy_form_text1": "Per inviarci un pagamento tramite Stripe, compila il modulo sottostante.",
    "buy_form_text2": "Verrai quindi reindirizzato a Stripe, controlla le informazioni di pagamento e poi scegli il tuo metodo di pagamento come sei solito fare.",
    "buy_terms_label": "Selezionando questa casella, accetti",
    "buy_terms_text": "i nostri T&C",
    "buy_action": "Paga con Stripe",

    "terms_title": "Termini e condizioni d'uso",
    "terms_text1": "L'uso del sito implica la piena accettazione delle condizioni generali d'uso descritte di seguito.",
    "terms_text2": "Queste condizioni d'uso possono essere modificate o integrate in qualsiasi momento, senza preavviso, pertanto gli utenti del sito sono invitati a consultarle regolarmente. Wall Street Web si riserva inoltre il diritto di cedere, trasferire, senza preavviso i diritti e/o gli obblighi di questi Termini e note legali.",
    "terms_text3": "Continuando a utilizzare il sito, l'utente riconosce di accettare le modifiche alle condizioni generali intervenute.",
    "terms_text4": "Descrizione dei servizi forniti",
    "terms_text5": "Lo scopo del sito www.pubclickmarketing.com è quello di fornire informazioni su tutte le attività dell'azienda. Il proprietario del sito si impegna a fornire informazioni il più possibile accurate. Tuttavia, non può essere ritenuto responsabile per omissioni, inesattezze e carenze nell'aggiornamento, sia da parte sua che di terzi partner che forniscono queste informazioni.",
    "terms_text6": "Tutte le informazioni offerte sono fornite a titolo indicativo, non sono esaustive e sono soggette a modifiche. Sono fornite con riserva di modifiche apportate dopo la loro messa online.",
    "terms_text7": "Gli spazi interattivi sono a disposizione degli utenti. Il proprietario del sito si riserva il diritto di eliminare, senza preavviso, qualsiasi contenuto pubblicato in questo spazio che contravvenga alla legislazione applicabile in Svizzera, in particolare alle disposizioni relative alla protezione dei dati. Se necessario, il proprietario del sito si riserva inoltre il diritto di mettere in discussione la responsabilità civile e/o penale dell'utente, in particolare in caso di messaggio razzista, offensivo, diffamatorio o pornografico, indipendentemente dal mezzo utilizzato (testo, fotografia...).",
    "terms_text8": "Limiti di responsabilità",
    "terms_text9": "Il sito www.pubclickmarketing.com non può essere ritenuto responsabile per errori tipografici o inesattezze presenti sul servizio, né per eventuali danni subiti a seguito del suo utilizzo. L'utente rimane responsabile della propria attrezzatura e del suo utilizzo, così come è lui a sostenere i costi diretti o indiretti conseguenti alla sua connessione a Internet.",
    "terms_text10": "L'utente declina la responsabilità di www.pubclickmarketing.com per qualsiasi danno che possa subire o far subire, direttamente o indirettamente, a seguito dei servizi offerti. Solo la responsabilità dell'utente è impegnata dall'uso del servizio offerto e libera espressamente il sito www.pubclickmarketing.com da qualsiasi responsabilità nei confronti di terzi.",
    "terms_text11": "Il sito www.pubclickmarketing.com contiene una serie di collegamenti ipertestuali ad altri siti (partner, informazioni ...) creati con l'autorizzazione del proprietario del sito. Tuttavia, il proprietario del sito non ha la possibilità di controllare il contenuto dei siti così visitati e declina pertanto ogni responsabilità per questo fatto quando i possibili rischi di contenuti illegali.",
    "terms_text12": "Proprietà intellettuale",
    "terms_text13": "Il proprietario del sito è il proprietario dei diritti di proprietà intellettuale o detiene i diritti di utilizzo su tutti gli elementi accessibili sul sito, in particolare i testi, le immagini, la grafica, il logo, le icone, i suoni, il software…",
    "terms_text14": "Qualsiasi riproduzione, rappresentazione, modifica, pubblicazione, adattamento totale o parziale degli elementi del sito, qualunque sia il mezzo o il processo utilizzato, è vietata senza previa autorizzazione scritta all'indirizzo e-mail: support@wallstreetweb.net.",
    "terms_text15": "Qualsiasi utilizzo non autorizzato del sito o di uno qualsiasi di questi elementi in esso contenuti sarà considerato come costituente una violazione e perseguito conformemente alle disposizioni degli articoli L.335-2 e seguenti del Codice della proprietà intellettuale.",
    "terms_text16": "Protezione della proprietà e delle persone - gestione dei dati personali:",
    "terms_text17": "www.pubclickmarketing.com raccoglie informazioni personali sull'utente solo per la necessità di determinati servizi offerti dal sito www.pubclickmarketing.com. L'utente fornisce queste informazioni con piena cognizione di causa, in particolare quando le inserisce lui stesso. Viene quindi specificato all'utente del sito www.pubclickmarketing.com l'obbligo o meno di fornire queste informazioni.",
    "terms_text18": "Ogni utente ha il diritto di accesso, rettifica, cancellazione e opposizione ai dati personali che lo riguardano. Per esercitare questo diritto, inviare la richiesta a www.pubclickmarketing.com tramite e-mail: e-mail del webmaster o presentando la sua richiesta scritta e firmata, accompagnata da una copia del documento di identità con firma del titolare del documento, specificando l'indirizzo al quale deve essere inviata la risposta.",
    "terms_text19": "Nessuna informazione personale dell'utente del sito www.pubclickmarketing.com viene pubblicata senza la conoscenza dell'utente, scambiata, trasferita, ceduta o venduta su qualsiasi supporto a terziies. Solo l'ipotesi di riscatto del sito www.pubclickmarketing.com e dei suoi diritti consentirebbe la trasmissione di tali informazioni al potenziale acquirente che a sua volta si troverebbe nello stesso obbligo di conservare e modificare i dati nei confronti dell'utente del sito www.pubclickmarketing.com.",
    "terms_text20": "Il sito www.pubclickmarketing.com è dichiarato alla CNIL con il numero 0.",
    "terms_text21": "Le banche dati sono protette dalle disposizioni della legge del 1° luglio 1998 che recepisce la direttiva 96/9 dell'11 marzo 1996 relativa alla tutela giuridica delle banche dati.",
    "terms_text22": "Collegamenti ipertestuali e cookie:",
    "terms_text23": "L'utente è informato che durante le sue visite al sito www.pubclickmarketing.com, uno o più cookie potrebbero essere installati automaticamente sul suo computer. Un cookie è un piccolo file, che non consente l'identificazione dell'utente, ma che registra informazioni relative alla navigazione di un computer su un sito. I dati così ottenuti hanno lo scopo di facilitare la navigazione successiva sul sito e sono anche destinati a consentire diverse misure di presenza.",
    "terms_text24": "La configurazione del software del browser consente di informare della presenza di cookie ed eventualmente di rifiutarli nel modo descritto al seguente indirizzo: www.cnil.fr",
    "terms_text25": "Il rifiuto di installare un cookie può comportare l'impossibilità di accedere a determinati servizi. L'utente può tuttavia configurare il proprio computer come segue, per rifiutare l'installazione di cookie:",
    "terms_text26": "In Internet Explorer: scheda strumenti / opzioni Internet. Fare clic su Privacy e scegliere Blocca tutti i cookie. Convalidare su Ok. In Netscape: scheda Modifica / Preferenze. Fare clic su Avanzate e scegliere Disattiva cookie. Convalida su Ok.",
    "terms_text27": "Legge applicabile e giurisdizione:",
    "terms_text28": "Qualsiasi controversia relativa all'uso del sito www.pubclickmarketing.com è soggetta alla legge svizzera. L'utente e www.pubclickmarketing.com accettano di sottoporsi alla giurisdizione esclusiva dei tribunali svizzeri in caso di controversia.",
    "terms_text29": "Avviso legale",
    "terms_text30": "Responsabile editoriale",

    "policy_title": "Premessa",
    "policy_text1": "Convalidando il suo pagamento, il Cliente dichiara di accettare senza riserve la totalità delle presenti condizioni generali di vendita.",
    "policy_text2": "Articolo 1 - Scopo",
    "policy_text3": "Lo scopo del presente contratto è il pagamento online delle fatture a Wall Street Web dal sito web publickmarketing.",
    "policy_text4": "Articolo 2 - Formule - Durata",
    "policy_text5": "Il Cliente paga uno dei servizi definiti nei termini del suo contratto stabiliti in anticipo e beneficerà durante il periodo contrattuale dell'accesso al servizio e secondo la tariffa in vigore il giorno dell'ordine.",
    "policy_text6": "Articolo 3 - Tariffe e modalità di pagamento",
    "policy_text7": "Le tariffe corrispondenti alla realizzazione di un servizio o all'accesso a un servizio online.",
    "policy_text8": "Per ottenere un account e iniziare a pagare le tue fatture, devi essere già cliente di un servizio offerto da Wall Street Web, in tal caso contattaci per ottenere le tue credenziali.",
    "policy_text9": "Sono proposti diversi metodi di pagamento:",
    "policy_text10": "Carta di credito secondo una procedura sicura (SSL) Bollettino di pagamento PostFinance Articolo 4 - Utilizzo del codice riservato Il nome utente e la password consentono al Cliente di identificarsi e di connettersi a una parte del Sito che gli consente di pagare le sue fatture. Possono essere modificati solo su richiesta del Cliente o su iniziativa di Wall Street Web. Il Cliente è pienamente responsabile dell'utilizzo delle informazioni di identificazione personale che lo riguardano. Il Cliente si impegna a mantenerle segrete e a non divulgarle in alcuna forma. Ogni transazione effettuata utilizzando il nome utente e la password del Cliente si considera effettuata dal Cliente.",
    "policy_text11": "Qualsiasi altro utilizzo è soggetto alla preventiva ed espressa autorizzazione di Wall Street Web.",
    "policy_text12": "In caso di smarrimento o furto di uno degli elementi di identificazione personale che lo riguardano, il Cliente dovrà scegliere una nuova password e un nuovo login e darne comunicazione a Wall Street Web tramite posta o e-mail al seguente indirizzo: support@wallstreetweb.net.",
    "policy_text13": "Articolo 5 - Validità degli scambi elettronici Il Cliente riconosce la validità e il valore probatorio degli scambi elettronici e delle registrazioni effettuate da publickmarketing e accetta che tali registrazioni ricevano lo stesso valore probatorio di uno scritto firmato a mano.",
    "policy_text14": "Articolo 6 - Risoluzione In caso di violazione da parte del Cliente di uno dei suoi obblighi essenziali, publickmarketing. si riserva il diritto di sospendere immediatamente e automaticamente la fornitura del Servizio e/o di recedere dal presente contratto dopo una diffida rimasta senza effetto per un mese o, senza preavviso.",
    "policy_text15": "In caso di risoluzione anticipata, publickmarketing non rimborserà in nessun caso le somme versate in tutto o in parte dal cliente.",
    "policy_text16": "Articolo 7 - Legge applicabile e foro competente Il presente contratto è soggetto alla legge svizzera. In caso di controversia relativa all'applicazione o all'interpretazione del presente contratto, le parti concordano di cercare una soluzione amichevole. In caso contrario, i tribunali svizzeri avranno giurisdizione esclusiva.",
}