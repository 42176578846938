export const static_translation = {
    "header_link1": "Home",
    "header_link2": "Lösung",
    "header_link3": "Dienste",
    "header_link4": "Pakete",
    "header_link5": "Kontakt",
    "homebanner_title": "Verweisen Sie auf Ihre Website und werben Sie ganz einfach im Internet",
    "homebanner_action": "Weiterlesen",
    "homehowworks_title": "Eine schlüsselfertige Lösung",
    "homehowworks_text": "Dank unserer Online-Werbetools können Sie Ihre Website mit wenigen Klicks überall auf der Welt teilen oder nur bestimmte Länder ansprechen.",
    "homehowworks_title1": "Einfach",
    "homehowworks_text1": "Wir kümmern uns um alles für Sie. Geben Sie uns Ihre Ziele und wir erledigen den Rest.",
    "homehowworks_title2": "Schnell",
    "homehowworks_text2": "Mit wenigen Klicks sind Sie täglich für Tausende von Besuchern sichtbar.",
    "homehowworks_title3": "Sicher",
    "homehowworks_text3": "Bezahlen Sie sicher online dank unseres Zahlungsmoduls, das für alle Kreditkarten verfügbar ist.",
    "homemoreinfos_title1": "Online-Kleinanzeigen",
    "homemoreinfos_text1": "Wir können für Sie Online-Kleinanzeigen auf verschiedenen Plattformen erstellen.",
    "homemoreinfos_title2": "Foren",
    "homemoreinfos_text2": "Der Zugang zu den Foren war oft Mitgliedern vorbehalten, wir können Ihre Links für Sie einfügen.",
    "homemoreinfos_title3": "SEO und Google SEO",
    "homemoreinfos_text3": "Wir sorgen für eine SEO auf der ersten Seite von Google entsprechend Ihren Schlüsselwörtern.",
    "homemoreinfos_title": "Auf Ihre Bedürfnisse zugeschnittene Dienste",
    "homemoreinfos_text": "Wir bieten verschiedene Dienste an, um Sie im Internet zu bewerben. Dank innovativer Technologie können wir Ihre Site veröffentlichen und auf Hunderten von Plattformen darauf verweisen.",
    "homeknowmore_title1": "Basisplan",
    "homeknowmore_text1_1": "Kleinanzeigen für 1 Woche",
    "homeknowmore_text1_2": "Link in Foren (1x pro Woche)",
    "homeknowmore_text1_3": "Basis-SEO",
    "homeknowmore_text1_4": "E-Mail-Support",
    "homeknowmore_title2": "Bronzeplan",
    "homeknowmore_text2_1": "Kleinanzeigen für 1 Woche",
    "homeknowmore_text2_2": "Link in Foren (1x pro Woche)",
    "homeknowmore_text2_3": "Premium-SEO",
    "homeknowmore_text2_4": "5/7-Telefonsupport",
    "homeknowmore_title3": "Premiumplan",
    "homeknowmore_text3_1": "Kleinanzeigen für 1 Woche",
    "homeknowmore_text3_2": "Link in Foren (2x pro Woche)",
    "homeknowmore_text3_3": "Premium SEO",
    "homeknowmore_text3_4": "7/7 telefonischer Support",
    "homeknowmore_action": "Jetzt kaufen",
    "homeknowmore_options_title": "Eine Rechnung bezahlen",
    "homeknowmore_options_text": "Sie haben einen bestimmten Vertrag mit uns und möchten Ihre Rechnung direkt im Internet bezahlen? Das ist einfach, unkompliziert und sicher. Klicken Sie unten und lassen Sie sich leiten.",
    "homeknowmore_options_action": "Meine Rechnung bezahlen",
    "homeknowmore_title": "Verschiedene Pakete je nach Ihren Zielen",
    "homeknowmore_text": "Je nach Ihren Bedürfnissen und Zielen stehen mehrere Formeln zur Verfügung. Ob für Kleinanzeigen, Foren, SEO oder sogar SEA, wir begleiten Sie bei Ihrem Vorgehen.",
    "footer_title": "Kontakt",
    "footer_text": "Kontaktieren Sie uns für weitere Informationen zu unseren Angeboten und für personalisierte Angebote.<br />Wir stehen Ihnen von Montag bis Freitag von 9 bis 19 Uhr zur Verfügung.",
    "footer_link1": "CGUs",
    "footer_link2": "AGB",
    "purchase_successfuly_title": "Kauf erfolgreich",
    "purchase_successfuly_text": "Ihre Zahlung wurde erfolgreich bestätigt, jetzt können Sie Ihre Anzeigen verstärken!",
    "purchase_successfuly_action": "Fertig",
    "purchase_cancel_title": "Kauf fehlgeschlagen",
    "purchase_cancel_text": "Ihre Zahlung konnte nicht erfolgreich bestätigt werden, versuchen Sie es später erneut",
    "purchase_cancel_action": "Ok",
    "buy_form_firstname": "Vorname Name*",
    "buy_form_lastname": "Nachname*",
    "buy_form_email": "E-Mail-Adresse*",
    "buy_form_phone": "Telefon",
    "buy_form_company": "Unternehmen",
    "buy_form_url": "URL Ihrer Site zum Verweisen",
    "buy_form_amount": "Betrag*",
    "buy_form_validation": "Bedingungen akzeptieren.",
    "buy_form_title": "Zahlung über Stripe vornehmen",
    "buy_form_text1": "Um uns eine Zahlung per Stripe zu senden, füllen Sie bitte das folgende Formular aus.",
    "buy_form_text2": "Sie werden dann zu Stripe weitergeleitet, überprüfen Sie die Zahlungsinformationen und wählen Sie dann wie gewohnt Ihre Zahlungsmethode.",
    "buy_terms_label": "Indem Sie dieses Kontrollkästchen aktivieren, akzeptieren Sie",
    "buy_terms_text": "unsere AGB",
    "buy_action": "Mit Stripe bezahlen",
    "terms_title": "Nutzungsbedingungen",
    "terms_text1": "Die Nutzung der Website setzt die vollständige Akzeptanz der unten beschriebenen allgemeinen Nutzungsbedingungen voraus.",
    "terms_text2": "Diese Nutzungsbedingungen können jederzeit und ohne Vorankündigung geändert oder ergänzt werden. Die Benutzer der Website werden daher gebeten, sie regelmäßig zu konsultieren. Wall Street Web behält sich außerdem das Recht vor, die Rechte und/oder Pflichten dieser Bedingungen und rechtlichen Hinweise ohne Vorankündigung abzutreten oder zu übertragen.",
    "terms_text3": "Durch die fortgesetzte Nutzung der Website erkennt der Benutzer an, die vorgenommenen Änderungen der allgemeinen Bedingungen zu akzeptieren.",
    "terms_text4": "Beschreibung der bereitgestellten Dienste",
    "terms_text5": "Der Zweck der Website www.pubclickmarketing.com besteht darin, Informationen zu allen Aktivitäten des Unternehmens bereitzustellen. Der Eigentümer der Website ist bestrebt, möglichst genaue Informationen bereitzustellen. Er kann jedoch nicht für Auslassungen, Ungenauigkeiten und Mängel bei der Aktualisierung verantwortlich gemacht werden, weder von ihm selbst noch von Drittpartnern. die diese Informationen bereitstellen.",
    "terms_text6": "Alle angebotenen Informationen dienen als Anhaltspunkt, sind nicht erschöpfend und können sich ändern. Sie werden vorbehaltlich der Änderungen bereitgestellt, die seit ihrer Onlinestellung vorgenommen wurden.",
    "terms_text7": "Den Benutzern stehen interaktive Bereiche zur Verfügung. Der Eigentümer der Website behält sich das Recht vor, ohne vorherige Ankündigung alle in diesem Bereich veröffentlichten Inhalte zu löschen, die gegen die in der Schweiz geltende Gesetzgebung verstoßen, insbesondere gegen die Bestimmungen zum Datenschutz. Gegebenenfalls behält sich der Eigentümer der Website auch das Recht vor, die zivil- und/oder strafrechtliche Haftung des Benutzers in Frage zu stellen, insbesondere im Falle einer rassistischen, beleidigenden, diffamierenden oder pornografischen Nachricht, unabhängig vom verwendeten Medium (Text, Fotografie ...).",
    "terms_text8": "Haftungsbeschränkungen",
    "terms_text9": "Die Website www.pubclickmarketing.com kann nicht für typografische Fehler oder Ungenauigkeiten im Service oder für Schäden verantwortlich gemacht werden, die durch seine Nutzung entstehen. Der Benutzer bleibt für seine Ausrüstung und deren Nutzung verantwortlich, ebenso wie er allein die direkten oder indirekten Kosten trägt, die durch seine Verbindung zum Internet entstehen.",
    "terms_text10": "Der Benutzer lehnt die Haftung von www.pubclickmarketing.com für Schäden ab, die er direkt oder indirekt infolge der angebotenen Dienste erleidet oder erleiden lässt. Die Nutzung des angebotenen Dienstes ist ausschließlich Sache des Benutzers, und er entbindet die Website www.pubclickmarketing.com ausdrücklich von jeglicher Haftung gegenüber Dritten.",
    "terms_text11": "Die Website www.pubclickmarketing.com enthält eine Reihe von Hypertext-Links zu anderen Websites (Partner, Informationen usw.), die mit Genehmigung des Eigentümers der Website eingerichtet wurden. Der Eigentümer der Website hat jedoch keine Möglichkeit, den Inhalt der so besuchten Websites zu überprüfen und lehnt daher jegliche Verantwortung dafür ab, wenn das mögliche Risiko illegaler Inhalte besteht.",
    "terms_text12": "Geistiges Eigentum",
    "terms_text13": "Der Eigentümer der Website ist Eigentümer der geistigen Eigentumsrechte oder besitzt die Nutzungsrechte an allen auf der Website zugänglichen Elementen, insbesondere an Texten, Bildern, Grafiken, Logos, Symbolen, Sounds, Software…",
    "terms_text14": "Jede Reproduktion, Darstellung, Änderung, Veröffentlichung, vollständige oder teilweise Anpassung der Elemente der Website, unabhängig von den verwendeten Mitteln oder Verfahren, ist ohne vorherige schriftliche Genehmigung an die E-Mail-Adresse support@wallstreetweb.net verboten.",
    "terms_text15": "Jede unbefugte Nutzung der Website oder eines der darin enthaltenen Elemente wird als Verstoß betrachtet und gemäß den Bestimmungen der Artikel L.335-2 ff. des Gesetzes zum geistigen Eigentum verfolgt.",
    "terms_text16": "Schutz von Eigentum und Personen – Verwaltung personenbezogener Daten:",
    "terms_text17": "www.pubclickmarketing.com erfasst personenbezogene Daten des Benutzers nur für die Nutzung bestimmter von der Website www.pubclickmarketing.com angebotener Dienste. Der Benutzer stellt diese Informationen in voller Kenntnis der Sachlage zur Verfügung, insbesondere wenn er sie selbst eingibt. Dem Benutzer der Website www.pubclickmarketing.com wird dann mitgeteilt, ob er diese Informationen zur Verfügung stellen muss oder nicht.",
    "terms_text18": "Jeder Benutzer hat ein Recht auf Zugriff, Berichtigung, Löschung und Widerspruch hinsichtlich der ihn betreffenden personenbezogenen Daten. Um dieses Recht auszuüben, senden Sie Ihre Anfrage per E-Mail an www.pubclickmarketing.com: E-Mail vom Webmaster oder indem Sie seine schriftliche und unterschriebene Anfrage zusammen mit einer Kopie des Ausweises mit Unterschrift des Inhabers des Dokuments einreichen und die Adresse angeben, an die die Antwort gesendet werden muss.",
    "terms_text19": "Keine persönlichen Informationen des Benutzers der Website www.pubclickmarketing.com werden ohne das Wissen des Benutzers veröffentlicht, ausgetauscht, übertragen, abgetreten oder auf irgendeinem Medium an Dritte verkauft. Nur die Annahme der Übernahme der Site www.pubclickmarketing.com und ihrer Rechte würde die Übermittlung dieser Informationen an den potenziellen Käufer ermöglichen, der wiederum die gleiche Verpflichtung zur Speicherung und Änderung von Daten in Bezug auf den Benutzer der Site www.pubclickmarketing.com hätte.",
    "terms_text20": "Die Site www.pubclickmarketing.com ist bei der CNIL unter der Nummer 0 gemeldet.",
    "terms_text21": "Datenbanken sind durch die Bestimmungen des Gesetzes vom 1. Juli 1998 zur Umsetzung der Richtlinie 96/9 vom 11. März 1996 über den rechtlichen Schutz von Datenbanken geschützt.",
    "terms_text22": "Hypertext-Links und Cookies:",
    "terms_text23": "Der Benutzer wird darüber informiert, dass während seiner Besuche auf der Site www.pubclickmarketing.com automatisch ein oder mehrere Cookies auf seinem Computer installiert werden können. Ein Cookie ist eine kleine Datei, die keine Identifizierung des Benutzers zulässt, aber Informationen über die Navigation eines Computers auf einer Website aufzeichnet. Die so erhaltenen Daten sollen die spätere Navigation auf der Website erleichtern und auch verschiedene Besuchermessungen ermöglichen.",
    "terms_text24": "Die Konfiguration der Browsersoftware ermöglicht es, über das Vorhandensein von Cookies zu informieren und diese ggf. auf die unter der folgenden Adresse beschriebene Weise abzulehnen: www.cnil.fr",
    "terms_text25": "Die Ablehnung der Installation eines Cookies kann dazu führen, dass auf bestimmte Dienste nicht zugegriffen werden kann. Der Benutzer kann seinen Computer jedoch wie folgt konfigurieren, um die Installation von Cookies abzulehnen:",
    "terms_text26": "In Internet Explorer: Registerkarte Extras / Internetoptionen. Klicken Sie auf Datenschutz und wählen Sie Alle Cookies blockieren. Bestätigen Sie mit OK. In Netscape: Registerkarte Bearbeiten / Einstellungen. Klicken Sie auf Erweitert und wählen Sie Cookies deaktivieren. Mit „OK“ bestätigen.",
    "terms_text27": "Anwendbares Recht und Gerichtsstand:",
    "terms_text28": "Jeder Rechtsstreit im Zusammenhang mit der Nutzung der Website www.pubclickmarketing.com unterliegt dem Schweizer Recht. Der Benutzer und www.pubclickmarketing.com vereinbaren, sich im Streitfall der ausschließlichen Gerichtsbarkeit der Schweizer Gerichte zu unterwerfen.",
    "terms_text29": "Rechtlicher Hinweis",
    "terms_text30": "Redaktionsleiter",
    "policy_title": "Präambel",
    "policy_text1": "Mit der Bestätigung seiner Zahlung erklärt der Kunde, die Gesamtheit dieser allgemeinen Verkaufsbedingungen vorbehaltlos zu akzeptieren.",
    "policy_text2": "Artikel 1 - Zweck",
    "policy_text3": "Der Zweck dieses Vertrags ist die Online-Zahlung von Rechnungen an Wall Street Web von der Website publickmarketing.",
    "policy_text4": "Artikel 2 - Formeln - Dauer",
    "policy_text5": "Der Kunde bezahlt für einen der in den im Voraus festgelegten Bedingungen seines Vertrags definierten Dienste und profitiert während der Vertragslaufzeit vom Zugang zum Dienst und gemäß dem am Tag der Bestellung geltenden Tarif.",
    "policy_text6": "Artikel 3 - Tarife und Zahlungsmethoden",
    "policy_text7": "Die Tarife, die der Realisierung eines Dienstes oder dem Zugang zu einem Online-Dienst entsprechen.",
    "policy_text8": "Um ein Konto zu erhalten und mit der Zahlung Ihrer Rechnungen zu beginnen, müssen Sie bereits Kunde eines von Wall Street angebotenen Dienstes sein. Web, in diesem Fall kontaktieren Sie uns, um Ihre Zugangsdaten zu erhalten.",
    "policy_text9": "Es werden mehrere Zahlungsmethoden angeboten:",
    "policy_text10": "Kreditkarte nach einem sicheren Verfahren (SSL) PostFinance Einzahlungsschein Artikel 4 - Verwendung des vertraulichen Codes Der Benutzername und das Passwort ermöglichen es dem Kunden, sich zu identifizieren und sich mit einem Teil der Site zu verbinden, der es ihm ermöglicht, seine Rechnungen zu bezahlen. Sie können nur auf Anfrage des Kunden oder auf Initiative von Wall Street Web geändert werden. Der Kunde ist voll verantwortlich für die Verwendung der ihn betreffenden persönlichen Identifikationsdaten. Der Kunde verpflichtet sich, diese geheim zu halten und sie in keiner Form preiszugeben. Jede Transaktion, die unter Verwendung des Benutzernamens und Passworts des Kunden durchgeführt wird, gilt als vom Kunden durchgeführt.",
    "policy_text11": "Jede andere Verwendung unterliegt der vorherigen und ausdrücklichen Genehmigung von Wall Street Web.",
    "policy_text12": "Bei Verlust oder Diebstahl eines ihn betreffenden persönlichen Identifikationselements muss der Kunde ein neues Passwort und einen neuen Login wählen und Wall Street Web per Post oder E-Mail an die folgende Adresse benachrichtigen: support@wallstreetweb.net.",
    "policy_text13": "Artikel 5 - Gültigkeit des elektronischen Austauschs Der Kunde erkennt die Gültigkeit und den Beweiswert des elektronischen Austauschs und der Aufzeichnungen von publickmarketing an und akzeptiert, dass diese Aufzeichnungen den gleichen Beweiswert haben wie ein handschriftlich unterzeichnetes Dokument.",
    "policy_text14": "Artikel 6 - Kündigung Im Falle eines Verstoßes des Kunden gegen eine seiner wesentlichen Verpflichtungen kann publickmarketing. behält sich das Recht vor, die Bereitstellung des Dienstes sofort und automatisch auszusetzen und/oder diesen Vertrag nach einer formellen Mitteilung, die einen Monat lang wirkungslos geblieben ist, oder ohne Vorankündigung zu kündigen.",
    "policy_text15": "Im Falle einer vorzeitigen Kündigung erstattet publickmarketing unter keinen Umständen die vom Kunden ganz oder teilweise gezahlten Beträge zurück.",
    "policy_text16": "Artikel 7 - Geltendes Recht und zuständige Gerichte Dieser Vertrag unterliegt schweizerischem Recht. Im Falle eines Streits über die Anwendung oder Auslegung dieses Vertrags vereinbaren die Parteien, eine gütliche Lösung anzustreben. Andernfalls sind ausschließlich die schweizerischen Gerichte zuständig.",
}