export const static_translation = {
    "header_link1": "Home",
    "header_link2": "Solution",
    "header_link3": "Services",
    "header_link4": "Packs",
    "header_link5": "Contact",
    "homebanner_title": "Reference your site and advertise easily on the internet",
    "homebanner_action": "Read more",
    "homehowworks_title": "A turnkey solution",
    "homehowworks_text": "Thanks to our online advertising tools, you can in a few clicks share your website anywhere in the world or target only certain countries.",
    "homehowworks_title1": "Simple",
    "homehowworks_text1": "We take care of everything for you, give us your goals and we do the rest.",
    "homehowworks_title2": "Fast",
    "homehowworks_text2": "In a few clicks, you will be visible to thousands of visitors every day.",
    "homehowworks_title3": "Secure",
    "homehowworks_text3": "Pay online securely thanks to our payment module available for all credit cards.",
    "homemoreinfos_title1": "Online classified ad",
    "homemoreinfos_text1": "We may create online classifieds for you on different platforms.",
    "homemoreinfos_title2": "Forums",
    "homemoreinfos_text2": "Access to the forums was often reserved for members, we can insert your links for you.",
    "homemoreinfos_title3": "SEO and Google SEO",
    "homemoreinfos_text3": "We ensure an SEO on the first page of google according to your keywords.",
    "homemoreinfos_title": "Services tailored to your needs",
    "homemoreinfos_text": "We offer various services to advertise you on the internet. Thanks to innovative technology, we can publish your site and reference it on hundreds of platforms.",
    "homeknowmore_title1": "Basic Plan",
    "homeknowmore_text1_1": "Classified ads for 1 week",
    "homeknowmore_text1_2": "Link in forums (1x per week)",
    "homeknowmore_text1_3": "Basic SEO",
    "homeknowmore_text1_4": "Email support",
    "homeknowmore_title2": "Bronze Plan",
    "homeknowmore_text2_1": "Classified ads for 1 week",
    "homeknowmore_text2_2": "Link in forums (1x per week)",
    "homeknowmore_text2_3": "Premium SEO",
    "homeknowmore_text2_4": "5/7d support by phone",
    "homeknowmore_title3": "Premium Plan",
    "homeknowmore_text3_1": "Classified ads for 1 week",
    "homeknowmore_text3_2": "Link in forums (2x per week)",
    "homeknowmore_text3_3": "Premium SEO",
    "homeknowmore_text3_4": "7/7 support by phone",
    "homeknowmore_action": "Buy now",
    "homeknowmore_options_title": "Pay an invoice",
    "homeknowmore_options_text": "You have a specific contract with us and you want to pay your invoice directly on the internet? It\'s simple, easy and secure, click below and let yourself be guided.",
    "homeknowmore_options_action": "Pay my invoice",
    "homeknowmore_title": "Different packs depending on your goals",
    "homeknowmore_text": "Several formulas are available depending on your needs and objectives. Whether for classified ads, forums, SEO or even SEA, we accompany you in your approach.",
    "footer_title": "Contact us",
    "footer_text": "Contact us for more information on our offers and for personalized quotes.<br />We are at your disposal from Monday to Friday from 9am to 7pm.",
    "footer_link1": "CGUs",
    "footer_link2": "T&Cs",
    "purchase_successfuly_title": "Purchase successfully",
    "purchase_successfuly_text": "Your payment has been confirmed successfully, now you can boost your ads!",
    "purchase_successfuly_action": "Finish",
    "purchase_cancel_title": "Purchase fail",
    "purchase_cancel_text": "Your payment cant be confirmed successfully, try again later",
    "purchase_cancel_action": "Ok",
    "buy_form_firstname": "First name*",
    "buy_form_lastname": "Last name*",
    "buy_form_email": "Email address*",
    "buy_form_phone": "Phone",
    "buy_form_company": "Company",
    "buy_form_url": "URL of your site to reference",
    "buy_form_amount": "Amount*",
    "buy_form_validation": "Accept terms.",
    "buy_form_title": "Make a payment through Stripe",
    "buy_form_text1": "To send us a payment by Stripe, please fill out the form below.",
    "buy_form_text2": "You will then be redirected to Stripe, check the payment information and then choose your payment method as you are used to doing.",
    "buy_terms_label": "By checking this box, you accept",
    "buy_terms_text": "our T&Cs",
    "buy_action": "Pay with Stripe",
    "terms_title": "Terms and conditions of use",
    "terms_text1": "The use of the site implies full acceptance of the general conditions of use described below.",
    "terms_text2": "These conditions of use may be modified or supplemented at any time, without notice, so users of the site are invited to consult them regularly. Wall Street Web also reserves the right to assign, transfer, without notice the rights and / or obligations of these Terms and legal notices.",
    "terms_text3": "By continuing to use the site, the user acknowledges accepting the changes to the general conditions that have occurred.",
    "terms_text4": "Description of the services provided",
    "terms_text5": "The purpose of the www.pubclickmarketing.com website is to provide information on all of the company's activities. The owner of the site strives to provide information as accurate as possible. However, it can not be held responsible for omissions, inaccuracies and deficiencies in the update, whether by itself or by third party partners who provide this information.",
    "terms_text6": "All the information offered is given as an indication, is not exhaustive, and is subject to change. They are given subject to changes that have been made since they were put online.",
    "terms_text7": "Interactive spaces are available to users. The owner of the site reserves the right to delete, without prior notice, any content posted in this space that would contravene the legislation applicable in Switzerland, in particular the provisions relating to data protection. If necessary, the owner of the site also reserves the right to question the civil and / or criminal liability of the user, in particular in the event of a racist, abusive, defamatory or pornographic message, regardless of the medium used (text, photography ...).",
    "terms_text8": "Limits of liability",
    "terms_text9": "The www.pubclickmarketing.com site can not be held responsible for typographical errors or inaccuracies appearing on the service, or for any damage suffered as a result of its use. The user remains responsible for his equipment and its use, in the same way he alone bears the direct or indirect costs following his connection to the Internet.",
    "terms_text10": "The user disclaims the responsibility of www.pubclickmarketing.com for any damage that he may suffer or cause to be suffered, directly or indirectly, as a result of the services offered. Only the responsibility of the user is engaged by the use of the service offered and it expressly releases the site www.pubclickmarketing.com from any responsibility vis-à-vis third parties.",
    "terms_text11": "The www.pubclickmarketing.com site contains a number of hypertext links to other sites (partners, information ...) set up with the authorization of the owner of the site. However, the owner of the site does not have the possibility to check the content of the sites thus visited and therefore declines any responsibility for this fact when the possible risks of illegal content.",
    "terms_text12": "Intellectual Property",
    "terms_text13": "The owner of the site is the owner of the intellectual property rights or holds the rights of use on all the elements accessible on the site, in particular the texts, images, graphics, logo, icons, sounds, software…",
    "terms_text14": "Any reproduction, representation, modification, publication, total or partial adaptation of the elements of the site, whatever the means or process used, is prohibited without prior written authorization to the email: support@wallstreetweb.net.",
    "terms_text15": "Any unauthorized use of the site or any of these elements it contains will be considered as constituting an infringement and prosecuted in accordance with the provisions of articles L.335-2 and following of the Intellectual Property Code.",
    "terms_text16": "Protection of property and people - management of personal data:",
    "terms_text17": "www.pubclickmarketing.com collects personal information about the user only for the need of certain services offered by the site www.pubclickmarketing.com. The user provides this information with full knowledge of the facts, in particular when he enters it himself. It is then specified to the user of the site www.pubclickmarketing.com the obligation or not to provide this information.",
    "terms_text18": "Any user has a right of access, rectification, deletion and opposition to personal data concerning him. To exercise this right, send your request to www.pubclickmarketing.com by email: email from the webmaster or by making his written and signed request, accompanied by a copy of the identity document with signature of the holder of the document, specifying the address to which the response must be sent.",
    "terms_text19": "No personal information of the user of the site www.pubclickmarketing.com is published without the knowledge of the user, exchanged, transferred, assigned or sold on any medium whatsoever to third parties. Only the assumption of redemption of the site www.pubclickmarketing.com and its rights would allow the transmission of said information to the potential purchaser who would in turn be given the same obligation to store and modify data with respect to the user of the site www.pubclickmarketing.com.",
    "terms_text20": "The www.pubclickmarketing.com site is declared to the CNIL under number 0.",
    "terms_text21": "Databases are protected by the provisions of the Act of 1 July 1998 transposing Directive 96/9 of 11 March 1996 on the legal protection of databases.",
    "terms_text22": "Hypertext links and cookies:",
    "terms_text23": "The user is informed that during his visits to the site www.pubclickmarketing.com, one or more cookies may be automatically installed on his computer. A cookie is a small file, which does not allow the identification of the user, but which records information relating to the navigation of a computer on a site. The data thus obtained are intended to facilitate subsequent navigation on the site, and are also intended to allow various measures of attendance.",
    "terms_text24": "The configuration of the browser software makes it possible to inform of the presence of cookies and possibly to refuse in the manner described at the following address: www.cnil.fr",
    "terms_text25": "Refusal to install a cookie may result in the impossibility of accessing certain services. The user can, however, configure his computer as follows, to refuse the installation of cookies:",
    "terms_text26": "In Internet Explorer: tool tab / internet options. Click Privacy and choose Block all cookies. Validate on Ok. In Netscape: Edit / Preferences tab. Click Advanced and choose Disable Cookies. Validate on Ok.",
    "terms_text27": "Applicable law and jurisdiction:",
    "terms_text28": "Any dispute in connection with the use of the www.pubclickmarketing.com site is subject to Swiss law. The user and www.pubclickmarketing.com agree to submit to the exclusive jurisdiction of the Swiss courts in the event of a dispute.",
    "terms_text29": "Legal notice",
    "terms_text30": "Editorial Manager",
    "policy_title": "Preamble",
    "policy_text1": "By validating his payment, the Customer declares to accept without reservation the entirety of these general conditions of sale.",
    "policy_text2": "Article 1 - Purpose",
    "policy_text3": "The purpose of this contract is the online payment of invoices to Wall Street Web from the website publickmarketing.",
    "policy_text4": "Article 2 - Formulas - Duration",
    "policy_text5": "The Customer pays for one of the services defined in the terms of his contract established in advance and will benefit during the contractual period from access to the service and according to the rate in force on the day of the order.",
    "policy_text6": "Article 3 - Rates and methods of payment",
    "policy_text7": "The rates corresponding to the realization of a service or access to an online service.",
    "policy_text8": "To get an account and start paying your bills, you must already be a customer of a service offered by Wall Street Web, in which case contact us to get your credentials.",
    "policy_text9": "Several methods of payment are proposed:",
    "policy_text10": "Credit card according to a secure procedure (SSL) PostFinance Payment slip Article 4 - Use of the confidential code The username and password allowing the Customer to identify himself and connect to a part of the Site allowing him to pay his invoices. They can only be changed at the request of the Client or at the initiative of Wall Street Web. The Customer is fully responsible for the use of personal identification information concerning him. The Customer undertakes to keep them secret and not to disclose them in any form whatsoever. Any transaction carried out using the Customer's username and password is deemed to have been carried out by the Customer.",
    "policy_text11": "Any other use is subject to the prior and express authorization of Wall Street Web.",
    "policy_text12": "In the event of loss or theft of one of the personal identification elements concerning him, the Customer must choose a new password and a new login and notify Wall Street Web by mail or e-mail to the following address: support@wallstreetweb.net.",
    "policy_text13": "Article 5 - Validity of electronic exchanges The Customer acknowledges the validity and probative value of the electronic exchanges and records made by publickmarketing and accepts that said records receive the same probative value as a handwritten signed writing.",
    "policy_text14": "Article 6 - Termination In the event of a breach by the Customer of one of its essential obligations, publickmarketing. reserves the right to immediately and automatically suspend the provision of the Service and/or terminate this contract after a formal notice that has remained without effect for one month or, without notice.",
    "policy_text15": "In the event of early termination, publickmarketing will under no circumstances reimburse the sums paid in whole or in part by the customer.",
    "policy_text16": "Article 7 - Applicable law and competent courts This contract is subject to Swiss law. In the event of a dispute concerning the application or interpretation of this contract, the parties agree to seek an amicable solution. Otherwise, the Swiss courts will have sole jurisdiction.",
}