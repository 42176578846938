export const static_translation = {
    "header_link1": "Главная",
    "header_link2": "Решение",
    "header_link3": "Услуги",
    "header_link4": "Пакеты",
    "header_link5": "Контакты",
    "homebanner_title": "Ссылайтесь на свой сайт и легко рекламируйте его в Интернете",
    "homebanner_action": "Подробнее",
    "homehowworks_title": "Готовое решение",
    "homehowworks_text": "Благодаря нашим инструментам онлайн-рекламы вы можете всего за несколько кликов поделиться своим сайтом в любой точке мира или настроить таргетинг только на определенные страны.",
    "homehowworks_title1": "Просто",
    "homehowworks_text1": "Мы обо всем позаботимся за вас, сообщите нам свои цели, и мы сделаем все остальное.",
    "homehowworks_title2": "Быстро",
    "homehowworks_text2": "За несколько кликов вы станете видны тысячам посетителей каждый день.",
    "homehowworks_title3": "Безопасно",
    "homehowworks_text3": "Оплачивайте онлайн безопасно благодаря нашему платежному модулю, доступному для всех кредитных карт.",
    "homemoreinfos_title1": "Онлайн-объявления",
    "homemoreinfos_text1": "Мы можем создавать для вас онлайн-объявления на разных платформах.",
    "homemoreinfos_title2": "Форумы",
    "homemoreinfos_text2": "Доступ к форумам часто был зарезервирован для участников, мы можем вставить ваши ссылки для вас.",
    "homemoreinfos_title3": "SEO и Google SEO",
    "homemoreinfos_text3": "Мы обеспечиваем SEO на первой странице Google в соответствии с вашими ключевыми словами.",
    "homemoreinfos_title": "Услуги, адаптированные под ваши потребности",
    "homemoreinfos_text": "Мы предлагаем различные услуги по вашей рекламе в Интернете. Благодаря инновационным технологиям мы можем опубликовать ваш сайт и ссылаться на него на сотнях платформ.",
    "homeknowmore_title1": "Базовый план",
    "homeknowmore_text1_1": "Объявления на 1 неделю",
    "homeknowmore_text1_2": "Ссылка на форумах (1 раз в неделю)",
    "homeknowmore_text1_3": "Базовый SEO",
    "homeknowmore_text1_4": "Поддержка по электронной почте",
    "homeknowmore_title2": "Бронзовый план",
    "homeknowmore_text2_1": "Объявления на 1 неделю",
    "homeknowmore_text2_2": "Ссылка на форумах (1 раз в неделю)",
    "homeknowmore_text2_3": "Премиум SEO",
    "homeknowmore_text2_4": "Поддержка по телефону 5/7 дней",
    "homeknowmore_title3": "Премиум план",
    "homeknowmore_text3_1": "Объявления на 1 неделю",
    "homeknowmore_text3_2": "Ссылка на форумах (2 раза в неделю)",
    "homeknowmore_text3_3": "Премиум SEO",
    "homeknowmore_text3_4": "Поддержка по телефону 7/7",
    "homeknowmore_action": "Купить сейчас",
    "homeknowmore_options_title": "Оплатить счет",
    "homeknowmore_options_text": "У вас есть определенный контракт с нами, и вы хотите оплатить свой счет напрямую в Интернете? Это просто, легко и безопасно, нажмите ниже и получите указания.",
    "homeknowmore_options_action": "Оплатить мой счет",
    "homeknowmore_title": "Различные пакеты в зависимости от ваших целей",
    "homeknowmore_text": "Доступно несколько формул в зависимости от ваших потребностей и задач. Будь то объявления, форумы, SEO или даже SEA, мы сопровождаем вас в вашем подходе.",
    "footer_title": "Свяжитесь с нами",
    "footer_text": "Свяжитесь с нами для получения дополнительной информации о наших предложениях и для получения персонализированных предложений.<br />Мы в вашем распоряжении с понедельника по пятницу с 9:00 до 19:00.",
    "footer_link1": "CGUs",
    "footer_link2": "T&Cs",
    "purchase_successfuly_title": "Покупка выполнена успешно",
    "purchase_successfuly_text": "Ваш платеж успешно подтвержден, теперь вы можете повышать эффективность своих объявлений!",
    "purchase_successfuly_action": "Готово",
    "purchase_cancel_title": "Покупка не удалась",
    "purchase_cancel_text": "Ваш платеж не может быть успешно подтвержден, попробуйте еще раз позже",
    "purchase_cancel_action": "ОК",
    "buy_form_firstname": "Имя*",
    "buy_form_lastname": "Фамилия*",
    "buy_form_email": "Адрес электронной почты*",
    "buy_form_phone": "Телефон",
    "buy_form_company": "Компания",
    "buy_form_url": "URL вашего сайта для ссылки",
    "buy_form_amount": "Сумма*",
    "buy_form_validation": "Принять условия.",
    "buy_form_title": "Сделать платеж через Stripe",
    "buy_form_text1": "Чтобы отправить нам платеж через Stripe, заполните форму ниже.",
    "buy_form_text2": "Затем вы будете перенаправлены в Stripe, проверьте платежную информацию и выберите способ оплаты, как вы обычно делаете.",
    "buy_terms_label": "Установив этот флажок, вы принимаете",
    "buy_terms_text": "наши положения и условия",
    "buy_action": "Оплатить через Stripe",
    "terms_title": "Условия использования",
    "terms_text1": "Использование сайта подразумевает полное принятие общих условий использования, описанных ниже.",
    "terms_text2": "Эти условия использования могут быть изменены или дополнены в любое время без предварительного уведомления, поэтому пользователям сайта предлагается регулярно с ними знакомиться. Wall Street Web также оставляет за собой право уступать, передавать без предварительного уведомления права и/или обязательства по настоящим Условиям и юридическим уведомлениям.",
    "terms_text3": "Продолжая использовать сайт, пользователь подтверждает принятие изменений общих условий, которые произошли.",
    "terms_text4": "Описание предоставляемых услуг",
    "terms_text5": "Целью веб-сайта www.pubclickmarketing.com является предоставление информации обо всех видах деятельности компании. Владелец сайта стремится предоставлять информацию как можно более точно. Однако он не может нести ответственности за упущения, неточности и недостатки в обновлении, как сам по себе, так и сторонние партнеры, которые предоставляют эту информацию.",
    "terms_text6": "Вся предлагаемая информация дается в качестве ориентира, не является исчерпывающей и может быть изменена. Она предоставляется с учетом изменений, которые были сделаны с момента ее размещения в сети.",
    "terms_text7": "Интерактивные пространства доступны для пользователей. Владелец сайта оставляет за собой право удалять без предварительного уведомления любой контент, размещенный в этом пространстве, который будет противоречить законодательству, действующему в Швейцарии, в частности положениям, касающимся защиты данных. При необходимости владелец сайта также оставляет за собой право оспорить гражданскую и/или уголовную ответственность пользователя, в частности, в случае расистского, оскорбительного, клеветнического или порнографического сообщения, независимо от используемого носителя (текст, фотография ...).",
    "terms_text8": "Ограничения ответственности",
    "terms_text9": "Сайт www.pubclickmarketing.com не может нести ответственность за типографские ошибки или неточности, появляющиеся на сервисе, или за любой ущерб, понесенный в результате его использования. Пользователь несет ответственность за свое оборудование и его использование, так же как он несет прямые или косвенные расходы, возникающие после его подключения к Интернету.",
    "terms_text10": "Пользователь отказывается от ответственности www.pubclickmarketing.com за любой ущерб, который он может понести или вызвать, прямо или косвенно, в результате предлагаемых услуг. Только пользователь несет ответственность за использование предлагаемой услуги, и это прямо освобождает сайт www.pubclickmarketing.com от любой ответственности по отношению к третьим лицам.",
    "terms_text11": "Сайт www.pubclickmarketing.com содержит ряд гипертекстовых ссылок на другие сайты (партнеров, информацию ...), созданных с разрешения владельца сайта. Однако владелец сайта не имеет возможности проверять содержимое посещаемых таким образом сайтов и, следовательно, снимает с себя любую ответственность за этот факт, когда возможны риски незаконного контента.",
    "terms_text12": "Интеллектуальная собственность",
    "terms_text13": "Владелец сайта является владельцем прав интеллектуальной собственности или имеет права использования всех элементов, доступных на сайте, в частности текстов, изображений, графики, логотипа, значков, звуков, программного обеспечения…",
    "terms_text14": "Любое воспроизведение, представление, изменение, публикация, полная или частичная адаптация элементов сайта, независимо от используемых средств или процесса, запрещены без предварительного письменного разрешения на адрес электронной почты: support@wallstreetweb.net.",
    "terms_text15": "Любое несанкционированное использование сайта или любого из этих элементов, которые он содержит, будет считаться нарушением и преследоваться в соответствии с положениями статей L.335-2 и последующих Кодекса интеллектуальной собственности.",
    "terms_text16": "Защита собственности и людей - управление персональными данными:",
    "terms_text17": "www.pubclickmarketing.com собирает персональные данные о пользователе только для нужд определенных услуг, предлагаемых сайтом www.pubclickmarketing.com. Пользователь предоставляет эту информацию с полным осознанием фактов, в частности, когда он вводит ее сам. Затем пользователю сайта www.pubclickmarketing.com указывается обязанность или не обязанность предоставлять эту информацию.",
    "terms_text18": "Любой пользователь имеет право доступа, исправления, удаления и возражения против персональных данных, касающихся его. Чтобы воспользоваться этим правом, отправьте свой запрос на www.pubclickmarketing.com по электронной почте: электронная почта от веб-мастера или сделав свой письменный и подписанный запрос, сопроводив его копией документа, удостоверяющего личность, с подписью владельца документа, указав адрес, на который необходимо отправить ответ.",
    "terms_text19": "Никакая личная информация пользователя сайта www.pubclickmarketing.com не публикуется без ведома пользователя, не обменивается, не передается, не уступается и не продается на каком-либо носителе третьим лицамies. Только предположение о выкупе сайта www.pubclickmarketing.com и его прав позволит передать указанную информацию потенциальному покупателю, который в свою очередь будет иметь такое же обязательство хранить и изменять данные в отношении пользователя сайта www.pubclickmarketing.com.",
    "terms_text20": "Сайт www.pubclickmarketing.com заявлен в CNIL под номером 0.",
    "terms_text21": "Базы данных защищены положениями Закона от 1 июля 1998 года, транспонирующего Директиву 96/9 от 11 марта 1996 года о правовой защите баз данных.",
    "terms_text22": "Гипертекстовые ссылки и файлы cookie:",
    "terms_text23": "Пользователь уведомлен о том, что во время его посещений сайта www.pubclickmarketing.com на его компьютере могут быть автоматически установлены один или несколько файлов cookie. Файл cookie — это небольшой файл, который не позволяет идентифицировать пользователя, но записывает информацию, касающуюся навигации компьютера по сайту. Полученные таким образом данные предназначены для облегчения последующей навигации по сайту, а также для обеспечения различных мер посещаемости.",
    "terms_text24": "Конфигурация программного обеспечения браузера позволяет информировать о наличии файлов cookie и, возможно, отказывать способом, описанным по следующему адресу: www.cnil.fr",
    "terms_text25": "Отказ от установки файла cookie может привести к невозможности доступа к определенным службам. Однако пользователь может настроить свой компьютер следующим образом, чтобы отказаться от установки файлов cookie:",
    "terms_text26": "В Internet Explorer: вкладка «Инструменты» / «Свойства обозревателя». Щелкните «Конфиденциальность» и выберите «Блокировать все файлы cookie». Подтвердите, нажав «ОК». В Netscape: вкладка «Изменить» / «Настройки». Щелкните «Дополнительно» и выберите «Отключить файлы cookie». Подтвердить в Ok.",
    "terms_text27": "Применимое право и юрисдикция:",
    "terms_text28": "Любой спор в связи с использованием сайта www.pubclickmarketing.com регулируется швейцарским законодательством. Пользователь и www.pubclickmarketing.com соглашаются подчиняться исключительной юрисдикции швейцарских судов в случае возникновения спора.",
    "terms_text29": "Юридическое уведомление",
    "terms_text30": "Менеджер редакции",

    "policy_title": "Преамбула",
    "policy_text1": "Подтверждая свой платеж, Клиент заявляет о принятии без оговорок всех настоящих общих условий продажи.",
    "policy_text2": "Статья 1 - Цель",
    "policy_text3": "Целью настоящего договора является онлайн-оплата счетов Wall Street Web с веб-сайта publickmarketing.",
    "policy_text4": "Статья 2 - Формулы - Продолжительность",
    "policy_text5": "Клиент оплачивает одну из услуг, определенных в условиях его договора, установленных заранее, и будет получать выгоду в течение договорного периода от доступа к услуге и в соответствии с ставкой, действующей на день заказа.",
    "policy_text6": "Статья 3 - Тарифы и способы оплаты",
    "policy_text7": "Тарифы, соответствующие реализации услуги или доступу к онлайн-услуге.",
    "policy_text8": "Чтобы получить учетную запись и начать оплачивать свои счета, вы должны уже быть клиентом услуги, предлагаемой Wall Street Web, в этом случае свяжитесь с нами, чтобы получить ваши учетные данные.",
    "policy_text9": "Предлагается несколько способов оплаты:",
    "policy_text10": "Кредитная карта по защищенной процедуре (SSL) PostFinance Платежная квитанция Статья 4 - Использование конфиденциального кода Имя пользователя и пароль, позволяющие Клиенту идентифицировать себя и подключаться к части Сайта, позволяющей ему оплачивать свои счета. Они могут быть изменены только по запросу Клиента или по инициативе Wall Street Web. Клиент несет полную ответственность за использование личной идентификационной информации, касающейся его. Клиент обязуется хранить их в тайне и не разглашать ни в какой форме. Любая транзакция, выполненная с использованием имени пользователя и пароля Клиента, считается выполненной Клиентом.",
    "policy_text11": "Любое другое использование подлежит предварительному и прямому разрешению Wall Street Web.",
    "policy_text12": "В случае потери или кражи одного из элементов личной идентификации, касающихся его, Клиент должен выбрать новый пароль и новый логин и уведомить Wall Street Web по почте или электронной почте по следующему адресу: support@wallstreetweb.net.",
    "policy_text13": "Статья 5. Действительность электронных обменов Клиент признает действительность и доказательную силу электронных обменов и записей, сделанных publickmarketing, и соглашается с тем, что указанные записи имеют такую ​​же доказательную силу, как и рукописный подписанный текст.",
    "policy_text14": "Статья 6. Расторжение В случае нарушения Клиентом одного из своих существенных обязательств publickmarketing. оставляет за собой право немедленно и автоматически приостановить предоставление Услуги и/или расторгнуть этот договор после официального уведомления, которое оставалось без эффекта в течение одного месяца или без уведомления.",
    "policy_text15": "В случае досрочного расторжения publickmarketing ни при каких обстоятельствах не возмещает суммы, полностью или частично уплаченные клиентом.",
    "policy_text16": "Статья 7 - Применимое право и компетентные суды Этот договор регулируется швейцарским законодательством. В случае возникновения спора относительно применения или толкования этого договора стороны соглашаются искать мирное решение. В противном случае швейцарские суды будут иметь исключительную юрисдикцию.",
}